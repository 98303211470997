import { render } from "./close.svg?vue&type=template&id=646e09a4"
const script = {}
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "646e09a4"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('646e09a4', script)) {
    api.reload('646e09a4', script)
  }
  
  module.hot.accept("./close.svg?vue&type=template&id=646e09a4", () => {
    api.rerender('646e09a4', render)
  })

}

script.__file = "src/assets/icons/close.svg"

export default script