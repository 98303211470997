import { render } from "./youtube.svg?vue&type=template&id=484910c2"
const script = {}
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "484910c2"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('484910c2', script)) {
    api.reload('484910c2', script)
  }
  
  module.hot.accept("./youtube.svg?vue&type=template&id=484910c2", () => {
    api.rerender('484910c2', render)
  })

}

script.__file = "src/assets/images/subscribes/youtube.svg"

export default script