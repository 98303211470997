// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@/assets/images/background/pattern.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("@/assets/images/background/gradient.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("@/assets/images/background/about-us-bg.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "#root[data-v-7ba5bd90] {\n  height: -moz-max-content;\n  height: max-content;\n  position: relative;\n  z-index: 1;\n  padding: 0;\n  padding-bottom: 220px;\n}\n#root[data-v-7ba5bd90]::after {\n  content: \"\";\n  position: fixed;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n  z-index: -1;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") center no-repeat;\n  background-attachment: fixed;\n  animation: bgRotate 1s linear forwards;\n}\n#root.off[data-v-7ba5bd90] {\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") center no-repeat, url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") top no-repeat !important;\n}\n#root.off[data-v-7ba5bd90]::after {\n  background: none;\n}\n@media screen and (min-width: 1280.98px) and (max-width: 1440.98px) {\n#root[data-v-7ba5bd90] {\n    padding-bottom: 180px;\n}\n}\n@media screen and (max-width: 1280.98px) {\n#root[data-v-7ba5bd90] {\n    padding-bottom: 162px;\n}\n}\n@media screen and (max-width: 768px) {\n#root[data-v-7ba5bd90] {\n    padding-bottom: 120px;\n}\n#root.off[data-v-7ba5bd90] {\n    background: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") top no-repeat !important;\n    background-size: 150% !important;\n}\n#root.off[data-v-7ba5bd90]::after {\n    background: none;\n}\n}\n.wrap[data-v-7ba5bd90] {\n  display: flex;\n  flex-direction: column;\n  overflow: hidden;\n  overflow-x: hidden;\n}\n.page-wrap[data-v-7ba5bd90] {\n  flex: 1 1 auto;\n}\n@media screen and (max-width: 768px) {\n.page-wrap[data-v-7ba5bd90] {\n    padding-top: 50px;\n}\n}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
