import { render } from "./reddit.svg?vue&type=template&id=49bbb0e4"
const script = {}
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "49bbb0e4"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('49bbb0e4', script)) {
    api.reload('49bbb0e4', script)
  }
  
  module.hot.accept("./reddit.svg?vue&type=template&id=49bbb0e4", () => {
    api.rerender('49bbb0e4', render)
  })

}

script.__file = "src/assets/images/subscribes/reddit.svg"

export default script