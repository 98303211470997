import { render } from "./facebook.svg?vue&type=template&id=19fd5176"
const script = {}
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "19fd5176"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('19fd5176', script)) {
    api.reload('19fd5176', script)
  }
  
  module.hot.accept("./facebook.svg?vue&type=template&id=19fd5176", () => {
    api.rerender('19fd5176', render)
  })

}

script.__file = "src/assets/images/subscribes/facebook.svg"

export default script