import { render } from "./twitter.svg?vue&type=template&id=7369ceef"
const script = {}
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "7369ceef"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('7369ceef', script)) {
    api.reload('7369ceef', script)
  }
  
  module.hot.accept("./twitter.svg?vue&type=template&id=7369ceef", () => {
    api.rerender('7369ceef', render)
  })

}

script.__file = "src/assets/images/subscribes/twitter.svg"

export default script