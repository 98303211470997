import { render } from "./instagram.svg?vue&type=template&id=9cfe29a4"
const script = {}
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "9cfe29a4"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('9cfe29a4', script)) {
    api.reload('9cfe29a4', script)
  }
  
  module.hot.accept("./instagram.svg?vue&type=template&id=9cfe29a4", () => {
    api.rerender('9cfe29a4', render)
  })

}

script.__file = "src/assets/images/subscribes/instagram.svg"

export default script