import { render } from "./telegram.svg?vue&type=template&id=03a5eb9e"
const script = {}
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "03a5eb9e"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('03a5eb9e', script)) {
    api.reload('03a5eb9e', script)
  }
  
  module.hot.accept("./telegram.svg?vue&type=template&id=03a5eb9e", () => {
    api.rerender('03a5eb9e', render)
  })

}

script.__file = "src/assets/images/subscribes/telegram.svg"

export default script