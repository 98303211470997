import { render } from "./github.svg?vue&type=template&id=12344293"
const script = {}
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "12344293"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('12344293', script)) {
    api.reload('12344293', script)
  }
  
  module.hot.accept("./github.svg?vue&type=template&id=12344293", () => {
    api.rerender('12344293', render)
  })

}

script.__file = "src/assets/images/subscribes/github.svg"

export default script