import { render } from "./weibo.svg?vue&type=template&id=e2cb8f38"
const script = {}
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "e2cb8f38"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('e2cb8f38', script)) {
    api.reload('e2cb8f38', script)
  }
  
  module.hot.accept("./weibo.svg?vue&type=template&id=e2cb8f38", () => {
    api.rerender('e2cb8f38', render)
  })

}

script.__file = "src/assets/images/subscribes/weibo.svg"

export default script